import { useEffect, useState, useCallback, MutableRefObject } from "react"
import { useThree } from "@react-three/fiber"
import {
  Intersection, LineSegments,
  Mesh, MeshBasicMaterial,
  Vector3, Object3D, Vector2
} from "three"
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import { FontLoader, Font } from "three/examples/jsm/loaders/FontLoader";
import { LineSegments2 } from "three/examples/jsm/lines/LineSegments2";

import RobotoRegular from '../components/Roboto_Regular.json'

import {
  getIndexCanonicalVector, makeLineSegments2,
  makeLineSegment, makeCircle, makePlane, updateLineSegments2,
  makeProjectionSegment
} from './utils';





const loader = new FontLoader();
let fontLoaded: Font = loader.parse(RobotoRegular);




/*loader.load('Roboto_Regular.json', function (font) {
  console.log(" Custom Font loaded");
  fontLoaded = font;
}, undefined, function (error) {
  console.log("Error: ", error)
}) */

interface MeasureToolProps {
  enabled: boolean,
  meshName: string,
  convexName: string,
  color: string,
  sizeLine: number
  suffixLabel: string,
  refToMesh: MutableRefObject<Mesh>,
  refToConvex: MutableRefObject<Mesh>,
  refToBase: MutableRefObject<Mesh>,
  onEndMeasure: (v: boolean) => void,
  editableRuler: (v: boolean) => void
}


// mancano da fare le proiezioni.
// Provare ad usare il raycast di default
export default function MeasureTool(props: MeasureToolProps) {
  const { gl } = useThree();
  const { raycaster } = useThree();
  const faces = ['A', 'A', 'B', 'B', 'C', 'C', 'D', 'D', 'E', 'E', 'F', 'F'];
  const radious = 0.03;
  let startFace = '';
  //let fontLoaded: Font;
  let text: TextGeometry;

  const [measurePending, setMeasurePending] = useState<boolean>(false);


  let textMesh: Mesh;

  let started: boolean = false;
  //const [started, setStarted] = useState<boolean>(false);

  // versore assi 
  let normalTemp: Vector3;
  // piano su cui faccio la correzione
  let plane: Vector3;
  let planeText: Mesh;
  let line: LineSegments;
  let startProjection2: LineSegments2;
  let endProjection: LineSegments;
  let startCircle: Mesh;
  let endCircle: Mesh;
  let pivot: Object3D;

  const [statePlane, setPlane] = useState<Vector3>(() => new Vector3());
  const [statePlaneText, setPlaneText] = useState<Mesh>();
  const [stateLine, setLine] = useState<LineSegments2>();
  const [stateStartProjection, setStartProjection] = useState<LineSegments2>();
  const [stateEndProjection, setEndProjection] = useState<LineSegments2>();
  const [stateStartCircle, setStartCircle] = useState<Mesh>();
  const [stateEndCircle, setEndCircle] = useState<Mesh>();
  const [statePivot, setPivot] = useState<Object3D>();



  // Dovrebbero avere sempre entrambi la medesima lunghezza
  // array utilizzati per la modalita' edit 

  let index_edit: number = -1;


  let editMode: boolean = false;

  const [edit, setEdit] = useState<boolean>(false);

  const [isStartPoint, setIsStartPoint] = useState<boolean>(false);
  const [index_to_edit, setIndex] = useState<number>(-1);


  let line2: LineSegments2;


  const [oldStartCircle, setOldStartCircle] = useState<Mesh[]>([]);
  const [oldEndCircle, setOldEndCircle] = useState<Mesh[]>([]);
  const [oldLine, setOldLine] = useState<LineSegments[]>([]);
  const [oldLine2, setOldLine2] = useState<LineSegments2[]>([]);
  const [oldStartProjection, setOldStartProjection] = useState<LineSegments2[]>([]);
  const [oldEndProjection, setOldEndProjection] = useState<LineSegments2[]>([]);
  const [oldFace, setOldFace] = useState<string[]>([]);
  const [oldPivot, setOldPivot] = useState<Object3D[]>([]);
  const [oldPlane, setOldPlane] = useState<Vector3[]>([]);
  const [oldTextMesh, setOldTextMesh] = useState<Mesh[]>([]);

  let start_index: number = -1;
  let end_index: number = -1;

  //const [touchMoved, setTouchMoved] = useState<boolean>(false);
  let touchMoved = false;

  // Per la logica di 'edit' mode,
  // quando individuo il punto di
  // interesezione con il raycaster,
  // dovrei ritornare il punto 
  // opposto e usare quest'ultimo
  // proprio come punto iniziale
  // dell'intero "algoritmo"
  function searchEndpoint(obj: Object3D, points: Object3D[]): number {
    if (points.length == 0)
      return -1;

    let obj_uuid = points.findIndex((element) => element.uuid == obj.uuid);
    //console.log("Found: ", obj_uuid);
    return obj_uuid;
  }


  // dato un array di intersection (ritornato dal Raycaster), cerco se e'
  // presente 
  function searchObjectByName(arr: Intersection[], name: string): number {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].object.name === name)
        return i;
    }
    return -1;
  }


  const findMesh = (name: string, intersection: Intersection[]): boolean => {
    return intersection.findIndex((element) => element.object.name === name) == -1 ? false : true;
  }

  const removeObjects = (): void => {
    if (statePivot !== undefined) {
      props.refToMesh.current.remove(statePivot);
    }
    if (stateLine !== undefined) {
      console.log("Removing line?")
      props.refToMesh.current.remove(stateLine);
    }
    if (stateStartCircle !== undefined) {
      props.refToMesh.current.remove(stateStartCircle);
    }
    if (stateEndCircle !== undefined) {
      props.refToMesh.current.remove(stateEndCircle);
    }
    if (stateStartProjection !== undefined) {
      props.refToMesh.current.remove(stateStartProjection);
    }
    if (stateEndProjection !== undefined) {
      props.refToMesh.current.remove(stateEndProjection);
    }
    // non serve perche' la misura e' nel pivot
    if (planeText !== undefined) {
      props.refToMesh.current.remove(planeText);
    }
  }

  // chiamare dispose() qui
  const interruptMeasure = useCallback((event: KeyboardEvent) => {
    console.log("Pressed key: ", event.key);
    if (event.key == "Escape") {
      if (!started) {
        props.onEndMeasure(false);
      } else {
        removeObjects()
        //props.isPending(false);
        props.onEndMeasure(false);
        started = false;
        //setStarted(false);
      }
    }
  }, []);


  // DEVO CONTROLLARE ANCHE LO STATO DEL FREE DRAW
  // FARLO SENZA USARE editRuler. Utilizzare invece
  // editMode

  const onClickMeasure = useCallback(() => {
    console.log("[!] Called with props: ", props.enabled);
    // rimettere false
    let intersect = raycaster.intersectObjects([props.refToMesh.current, props.refToConvex.current], false);
    if (intersect.length >= 2) {
      console.log("Intersect with: ", intersect)

      // qua devo fare il replace degli oggetti che modifico
      if (!started) {
        setMeasurePending(true);
        if (intersect[0].normal)
          normalTemp = intersect[0].normal.clone();
        let v0 = intersect[0].point;

        let vl = props.refToMesh.current.worldToLocal(v0);
        //let vl = v0.clone()
        plane = vl.clone();

        line = makeLineSegment(vl, vl, props.sizeLine);
        line.name = props.suffixLabel;

        startCircle = makeCircle(radious);
        startCircle.position.copy(vl.clone());

        let widthPlane: number = 0.6;
        let heightPlane: number = 0.2;

        pivot = new Object3D();

        // qui era diviso 2 il widthPlane
        planeText = makePlane(widthPlane, heightPlane / 2);
        pivot.position.copy(vl.clone());

        pivot.add(planeText)

        line2 = makeLineSegments2(vl, vl, props.sizeLine, gl.getSize(new Vector2()));
        //line2.name = props.suffixLabel;

        line.frustumCulled = false;
        props.refToMesh.current.add(line2);

        text = new TextGeometry("0cm/0.0in", {
          font: fontLoaded,
          size: 0.09,
          height: 0.0005,
          bevelSize: 0,
          bevelThickness: 0
        });

        text.center();
        const materialText = new MeshBasicMaterial({
          color: 0x0,
          transparent: false
        })
        textMesh = new Mesh(text, materialText);
        pivot.add(textMesh);

        // devo cambiare la coordinata corrispondente al versore degli assi
        // altrimenti su textMesh si verifica il flickering
        // let normalPlane: Vector3 = intersect[0].normal;
        let index = getIndexCanonicalVector(normalTemp);
        textMesh.position.setComponent(index, 0.002 * Math.sign(normalTemp.getComponent(index)))

        props.refToMesh.current.add(startCircle);
        props.refToMesh.current.add(pivot)

        startFace = '';

        if (intersect[0].faceIndex !== undefined) {
          startFace = faces[intersect[0].faceIndex];
        }

        // la rotazione che applico qua dovrebbe essere
        // l'ultima che applico 
        switch (startFace) {
          case 'A': planeText.rotation.y = Math.PI * 0.5; textMesh.rotation.y = Math.PI * 0.5; startCircle.rotation.y = Math.PI * 0.5; break;
          case 'B': planeText.rotation.y = -Math.PI * 0.5; textMesh.rotation.y = -Math.PI * 0.5; startCircle.rotation.y = -Math.PI * 0.5; break;
          case 'C': planeText.rotation.x = -Math.PI * 0.5; textMesh.rotation.x = -Math.PI * 0.5; startCircle.rotation.x = -Math.PI * 0.5; break;
          case 'D': planeText.rotation.x = Math.PI * 0.5; textMesh.rotation.x = Math.PI * 0.5; startCircle.rotation.x = Math.PI * 0.5; break;
          case 'E': break;
          case 'F': planeText.rotation.y = Math.PI; textMesh.rotation.y = Math.PI; startCircle.rotation.y = -Math.PI; break;
        }

        let newEnd = props.refToConvex.current.worldToLocal(intersect[1].point);
        //startProjection = makeProjectionSegment(vl, newEnd, intersect[0].normal);

        startProjection2 = makeProjectionSegment(vl, newEnd, intersect[0].normal, props.sizeLine, gl.getSize(new Vector2()))
        props.refToMesh.current.add(startProjection2);

        // le salvo temporaneamente
        // in modo da eliminare i vari componenti
        // nel caso in cui l'utente disabiliti il ruler
        setLine(line2);
        setPivot(pivot);
        setStartCircle(startCircle);
        setStartProjection(startProjection2);
        started = true;
        //return;
        //setStarted(true);

        //props.isPending(true);
      } else if (started) {

        // significa che devo concludere la linea

        // se e' impostata da qua devo recuperare la bbox

        let index_bbox = searchObjectByName(intersect, "bbox");
        let index_convex = searchObjectByName(intersect, "convex");

        let endFace = ''
        if (intersect[0].faceIndex !== undefined) {
          endFace = faces[intersect[index_bbox].faceIndex];
        }
        if (endFace !== startFace) {
          //console.log("Start Face: ", startFace, " end face: ", endFace)
          return
        }

        const positions = (
          line.geometry.attributes.position
        ).array
        let end = props.refToMesh.current.worldToLocal(intersect[index_bbox].point);
        positions[3] = end.x
        positions[4] = end.y
        positions[5] = end.z
        line.geometry.attributes.position.needsUpdate = true;

        endCircle = makeCircle(radious);
        endCircle.position.copy(end.clone());

        // in teoria non dovrebbe servire
        // devo ruotare anche la label
        switch (startFace) {
          case 'A': endCircle.rotation.y = Math.PI * 0.5; break;
          case 'B': endCircle.rotation.y = -Math.PI * 0.5; break;
          case 'C': endCircle.rotation.x = -Math.PI * 0.5; break;
          case 'D': endCircle.rotation.x = Math.PI * 0.5; break;
          case 'E': break;
          case 'F': endCircle.rotation.y = Math.PI; break;
        }

        //let proj = computeProjection(intersect[0].point, intersect[1].point,
        //    intersect[0].normal);
        let newEnd = props.refToConvex.current.worldToLocal(intersect[index_convex].point);
        //let newEnd = intersect[1].point;
        //let start = props.refToMesh.current.worldToLocal(intersect[0].point);

        //endProjection = makeProjectionSegment(end, newEnd, intersect[0].normal);
        let endProjection2 = makeProjectionSegment(end, newEnd, intersect[index_convex].normal, props.sizeLine, gl.getSize(new Vector2()));

        props.refToMesh.current.add(endProjection2);
        props.refToMesh.current.add(endCircle);
        started = false;
        //setStarted(false);

        // occhio, se sono in edit mode
        // non dovrei aggiungerli nuovamente
        // alla lista, il numero e' il solito
        //editMode = false;
        setMeasurePending(false);
        props.onEndMeasure(false);

        // devo salvare qua tutti i dati
        //if (started && !editMode) {
        console.log("Saving...");
        if (editMode === false) {
          setOldLine([...oldLine, line]);
          setOldLine2([...oldLine2, line2]);
          setOldStartCircle([...oldStartCircle, startCircle]);
          setOldEndCircle([...oldEndCircle, endCircle]);
          setOldFace([...oldFace, startFace]);
          setOldEndProjection([...oldEndProjection, endProjection2]);
          setOldStartProjection([...oldStartProjection, startProjection2]);
          setOldPivot([...oldPivot, pivot]);
          setOldPlane([...oldPlane, plane]);
          setOldTextMesh([...oldTextMesh, textMesh]);
        }
      }
    }
  }, [props.enabled])


  // in questa funzione continuo ad aggiornare
  // le misure prese
  // in modalita' touch questa viene useguita al secondo
  // click, che corrisponde a quando chiudo la misura

  // per verificare se sono in modalita' edit
  // posso controllare se l'indice index_edit e' impostato o no
  // oppure controllare semplicemente la variabile edit mode
  const onMouseMoveMeasure = useCallback(() => {
    if (started) {
      //console.log("Warn... edit = ",editMode, "started: ",started)
      let intersect = raycaster.intersectObjects([props.refToMesh.current,
      props.refToConvex.current], false)
      //console.log("Lenght of intersection: ",intersect.length);
      if (findMesh(props.meshName, intersect))
        //console.log("BBox found moving");
        if (findMesh(props.convexName, intersect))
          //console.log("Convex found moving");
          //console.log("Meshes found while moving...");
          if (intersect.length == 2) {
            const positions = (line.geometry.attributes.position).array
            const v0 = new Vector3(positions[0], positions[1], positions[2]);
            const v1 = new Vector3(
              intersect[0].point.x,
              intersect[0].point.y,
              intersect[0].point.z
            )

            let vl = props.refToMesh.current.worldToLocal(v1);
            //let vl = v1.clone();
            if (startFace == 'A' || startFace == 'B')
              vl.x = plane.x
            if (startFace == 'C' || startFace == 'D')
              vl.y = plane.y
            if (startFace == 'E' || startFace == 'F')
              vl.z = plane.z

            positions[3] = vl.x;
            positions[4] = vl.y;
            positions[5] = vl.z;

            props.refToMesh.current.remove(line2);
            line2 = updateLineSegments2(line2, v0, vl, props.sizeLine, gl.getSize(new Vector2()));
            setLine(line2);
            //console.log("Line2: ",line2);
            props.refToMesh.current.add(line2);

            line.geometry.attributes.position.needsUpdate = true;
            const distance: number = v0.distanceTo(vl) * 100;
            pivot.position.lerpVectors(v0, vl, 0.5);

            let inch = distance / 2.54;
            // conta solo il parametro height
            textMesh.geometry = new TextGeometry(distance.toFixed(0).toString() + "cm" + "/" + inch.toFixed(2).toString() + "in", {
              font: fontLoaded,
              size: 0.05,
              height: 0,
              bevelSize: 0.0005,
              bevelThickness: 0.0005,
            });
            textMesh.geometry.center();
          }
    }
  }, [props.enabled]);

  useEffect(() => {
    console.log("Called")
    if (props.enabled === true) {
      gl.domElement.removeEventListener("click", onClickEdit, false);
      gl.domElement.removeEventListener("mousemove", onMoveEdit, false);
    } else {
      gl.domElement.removeEventListener("click", onClickEdit, false);
      gl.domElement.removeEventListener("mousemove", onMoveEdit, false);
      gl.domElement.addEventListener("click", onClickEdit, false);
      gl.domElement.addEventListener("mousemove", onMoveEdit, false);
    }

    return () => {
      gl.domElement.removeEventListener("click", onClickEdit, false);
      gl.domElement.removeEventListener("mousemove", onMoveEdit, false);
    }

  }, [oldStartCircle, oldEndCircle, props.enabled, edit, index_to_edit, isStartPoint]);


  const onClickEdit = useCallback(() => {
    console.log("[ON EDIT CLICK CALLED]");
    console.log("[+] Searching on: ", oldStartCircle.concat(oldEndCircle))
    let intersect = raycaster.intersectObjects([props.refToMesh.current, props.refToConvex.current], false);
    let intersectPoints = raycaster.intersectObjects(oldStartCircle.concat(oldEndCircle), false);
    console.log("Intersect points...", intersectPoints)
    if (!edit) {
      if (intersectPoints.length > 0) {
        let obj = intersectPoints[0].object;
        start_index = searchEndpoint(obj, oldStartCircle);
        end_index = searchEndpoint(obj, oldEndCircle);
        if (start_index === -1 && end_index === -1) {
          console.log("Something wrong...");
          return;
        }
        console.log("Here...")
        if (start_index !== -1) {
          startFace = oldFace[start_index];
          line = oldLine[start_index];
          line2 = oldLine2[start_index];
          plane = oldPlane[start_index];
          pivot = oldPivot[start_index];
          textMesh = oldTextMesh[start_index];
          props.refToMesh.current.remove(oldStartCircle[start_index]);
          props.refToMesh.current.remove(oldStartProjection[start_index]);

          props.refToMesh.current.remove(oldPivot[start_index]);
          //props.refToMesh.current.remove(oldTextMesh[start_index]);

          index_edit = start_index;
          const positions = (line.geometry.attributes.position).array;
          let vectorTemp: number;
          // scambio la coordinata X
          vectorTemp = positions[3];
          positions[3] = positions[0];
          positions[0] = vectorTemp
          // scambio la coordinata Y
          vectorTemp = positions[4];
          positions[4] = positions[1];
          positions[1] = vectorTemp
          // scambio la coordinata Z
          vectorTemp = positions[5];
          positions[5] = positions[2];
          positions[2] = vectorTemp
          line.geometry.attributes.position.needsUpdate = true;

          //props.refToMesh.current.remove(line);
          props.refToMesh.current.remove(line2);

          endCircle = oldStartCircle[start_index];
          startCircle = oldEndCircle[start_index];

          //line2 = makeLineSegments2(endCircle.position, startCircle.position, props.sizeLine, gl.getSize(new Vector2()));
          //props.refToMesh.current.add(line2);
          setEdit(true);
          setIndex(start_index);
          setIsStartPoint(true);
          return;
        } else if (start_index === -1 && end_index !== -1) {
          console.log("Found end point")
          startFace = oldFace[end_index];
          line = oldLine[end_index];
          line2 = oldLine2[end_index];
          plane = oldPlane[end_index];
          pivot = oldPivot[end_index];
          textMesh = oldTextMesh[end_index];
          props.refToMesh.current.remove(line2);
          props.refToMesh.current.remove(oldEndCircle[end_index]);
          props.refToMesh.current.remove(oldEndProjection[end_index]);

          props.refToMesh.current.remove(oldPivot[end_index]);
          //props.refToMesh.current.remove(oldTextMesh[end_index]);

          index_edit = end_index;
          setIndex(end_index);
          setIsStartPoint(false);
          setEdit(true);
          return;
        }
      }
    } else if (edit) {
      console.log("Closing edit..");
      if (intersect.length == 2) {
        let index_bbox = searchObjectByName(intersect, "bbox");
        let index_convex = searchObjectByName(intersect, "convex");

        let endFace = ''
        if (intersect[0].faceIndex !== undefined) {
          endFace = faces[intersect[index_bbox].faceIndex];
        }
        if (endFace !== startFace) {
          //console.log("Start Face: ", startFace, " end face: ", endFace)
          return
        }

        let line = oldLine[index_to_edit];
        startFace = oldFace[index_to_edit];
        const positions = (
          line.geometry.attributes.position
        ).array
        let end = props.refToMesh.current.worldToLocal(intersect[index_bbox].point);
        positions[3] = end.x
        positions[4] = end.y
        positions[5] = end.z
        line.geometry.attributes.position.needsUpdate = true;

        endCircle = makeCircle(radious);
        endCircle.position.copy(end.clone());

        // in teoria non dovrebbe servire
        // devo ruotare anche la label
        switch (startFace) {
          case 'A': endCircle.rotation.y = Math.PI * 0.5; break;
          case 'B': endCircle.rotation.y = -Math.PI * 0.5; break;
          case 'C': endCircle.rotation.x = -Math.PI * 0.5; break;
          case 'D': endCircle.rotation.x = Math.PI * 0.5; break;
          case 'E': break;
          case 'F': endCircle.rotation.y = Math.PI; break;
        }

        //let proj = computeProjection(intersect[0].point, intersect[1].point,
        //    intersect[0].normal);
        let newEnd = props.refToConvex.current.worldToLocal(intersect[index_convex].point);
        //let newEnd = intersect[1].point;
        //let start = props.refToMesh.current.worldToLocal(intersect[0].point);

        //endProjection = makeProjectionSegment(end, newEnd, intersect[0].normal);
        let endProjection2 = makeProjectionSegment(end, newEnd, intersect[index_convex].normal, props.sizeLine, gl.getSize(new Vector2()));

        props.refToMesh.current.add(endProjection2);
        props.refToMesh.current.add(endCircle);
        setEdit(false);

        const newLine = oldLine.map((element, index) => {
          if (index === index_to_edit) {
            return line;
          } else {
            return element
          }
        })
        setOldLine(newLine);

        const newLine2 = oldLine2.map((element, index) => {
          if (index === index_to_edit) {
            return line2;
          } else {
            return element;
          }
        })
        setOldLine2(newLine2);
        
        // qua devo fare il replace degli oggetti 
        if (isStartPoint) {
          console.log("Save new start point");
          // il vecchio punto iniziale diventa un nuovo punto finale
          // il vecchio punto finale diventa un nuovo punto iniziale
          
          const newStart = oldStartCircle.map((element,index)=> {
            if (index === index_to_edit) {
              return oldEndCircle[index_to_edit];
            } else {
              return element;
            }
          });
          setOldStartCircle(newStart);

          const newEndCircle = oldEndCircle.map((element,index)=> {
            if (index === index_to_edit) {
              return endCircle;
            } else {
              return element;
            }
          });
          setOldEndCircle(newEndCircle);

          const newStartProjection = oldStartProjection.map((element, index)=> {
            if (index === index_to_edit) {
              return oldEndProjection[index_to_edit];
            } else {
              return element;
            }

          });
          setOldStartProjection(newStartProjection);

          const newEndProjection = oldEndProjection.map((element, index)=> {
            if (index === index_to_edit) {
              return endProjection2;
            } else {
              return element;
            }
          });
          setOldEndProjection(newEndProjection);

        } else {
          
          console.log("Save new end point");
          console.log("Saving after end ");
          setEdit(false);
          // in questo caso salvo normalmente senza invertire i componenti
          // iniziali con quelli finali
          const newProjection = oldEndProjection.map((element, index) => {
            if (index === index_to_edit) {
              console.log("Replacing element at index: ", index)
              return endProjection2
            } else {
              return element;
            }
          });
          setOldEndProjection(newProjection);

          setOldEndCircle(oldEndCircle.map((element,index)=> {
            if (index === index_to_edit) {
              return endCircle;
            } else {
              return element;
            }
          }));
        }
      }
      
    }


  }, [oldEndCircle, oldStartCircle, edit]);

  const onMoveEdit = useCallback(() => {
    //console.log("Edit is: ", edit)
    if (edit) {
      //console.log("Moving mouse in editing mode");
      //console.log("Index to edit: ", index_to_edit);
      //console.log("Is starting point? ", isStartPoint);
      let intersect = raycaster.intersectObjects([props.refToMesh.current,
        props.refToConvex.current], false)
        //console.log("Lenght of intersection: ",intersect.length);
        if (findMesh(props.meshName, intersect))
          //console.log("BBox found moving");
          if (findMesh(props.convexName, intersect))
            //console.log("Convex found moving");
            //console.log("Meshes found while moving...");
            if (intersect.length == 2) {
              line = oldLine[index_to_edit];
              pivot = oldPivot[index_to_edit];
              textMesh = oldTextMesh[index_to_edit];
              startFace = oldFace[index_to_edit];
              plane = oldPlane[index_to_edit];
              props.refToMesh.current.add(pivot)
              const positions = (line.geometry.attributes.position).array
              const v0 = new Vector3(positions[0], positions[1], positions[2]);
              const v1 = new Vector3(
                intersect[0].point.x,
                intersect[0].point.y,
                intersect[0].point.z
              )
  
              let vl = props.refToMesh.current.worldToLocal(v1);
              //let vl = v1.clone();
              if (startFace == 'A' || startFace == 'B')
                vl.x = plane.x
              if (startFace == 'C' || startFace == 'D')
                vl.y = plane.y
              if (startFace == 'E' || startFace == 'F')
                vl.z = plane.z
  
              positions[3] = vl.x;
              positions[4] = vl.y;
              positions[5] = vl.z;
  
              props.refToMesh.current.remove(line2);
              line2 = updateLineSegments2(line2, v0, vl, props.sizeLine, gl.getSize(new Vector2()));
              setLine(line2);
              //console.log("Line2: ",line2);
              props.refToMesh.current.add(line2);
  
              line.geometry.attributes.position.needsUpdate = true;
              const distance: number = v0.distanceTo(vl) * 100;
              pivot.position.lerpVectors(v0, vl, 0.5);
  
              let inch = distance / 2.54;
              // conta solo il parametro height
              textMesh.geometry = new TextGeometry(distance.toFixed(0).toString() + "cm" + "/" + inch.toFixed(2).toString() + "in", {
                font: fontLoaded,
                size: 0.05,
                height: 0,
                bevelSize: 0.0005,
                bevelThickness: 0.0005,
              });
              textMesh.geometry.center();
            }
    }

  }, [edit, index_to_edit, isStartPoint]);


  // in questa funzione inizializzo la modifica
  // e dovrei anche stoppare l'OrbitControl
  const onTouchEditStart = useCallback((e: TouchEvent) => {
    e.preventDefault();
    console.log("Touch start...");
    //let intersect = raycaster.intersectObjects([props.refToMesh.current, props.refToConvex.current], false);
    let intersectPoints = raycaster.intersectObjects(oldStartCircle.concat(oldEndCircle), false);
    if (!edit) {
      if (intersectPoints.length > 0) {
        let obj = intersectPoints[0].object;
        start_index = searchEndpoint(obj, oldStartCircle);
        end_index = searchEndpoint(obj, oldEndCircle);
        if (start_index === -1 && end_index === -1) {
          console.log("Something wrong...");
          return;
        }
        console.log("Here...")
        if (start_index !== -1) {
          startFace = oldFace[start_index];
          line = oldLine[start_index];
          line2 = oldLine2[start_index];
          plane = oldPlane[start_index];
          pivot = oldPivot[start_index];
          textMesh = oldTextMesh[start_index];
          props.refToMesh.current.remove(oldStartCircle[start_index]);
          props.refToMesh.current.remove(oldStartProjection[start_index]);

          props.refToMesh.current.remove(oldPivot[start_index]);
          //props.refToMesh.current.remove(oldTextMesh[start_index]);

          index_edit = start_index;
          const positions = (line.geometry.attributes.position).array;
          let vectorTemp: number;
          // scambio la coordinata X
          vectorTemp = positions[3];
          positions[3] = positions[0];
          positions[0] = vectorTemp
          // scambio la coordinata Y
          vectorTemp = positions[4];
          positions[4] = positions[1];
          positions[1] = vectorTemp
          // scambio la coordinata Z
          vectorTemp = positions[5];
          positions[5] = positions[2];
          positions[2] = vectorTemp
          line.geometry.attributes.position.needsUpdate = true;

          //props.refToMesh.current.remove(line);
          props.refToMesh.current.remove(line2);

          endCircle = oldStartCircle[start_index];
          startCircle = oldEndCircle[start_index];

          //line2 = makeLineSegments2(endCircle.position, startCircle.position, props.sizeLine, gl.getSize(new Vector2()));
          //props.refToMesh.current.add(line2);
          setEdit(true);
          setIndex(start_index);
          setIsStartPoint(true);
          props.editableRuler(true);
          return;
        } else if (start_index === -1 && end_index !== -1) {
          console.log("Found end point")
          startFace = oldFace[end_index];
          line = oldLine[end_index];
          line2 = oldLine2[end_index];
          plane = oldPlane[end_index];
          pivot = oldPivot[end_index];
          textMesh = oldTextMesh[end_index];
          props.refToMesh.current.remove(line2);
          props.refToMesh.current.remove(oldEndCircle[end_index]);
          props.refToMesh.current.remove(oldEndProjection[end_index]);

          props.refToMesh.current.remove(oldPivot[end_index]);
          //props.refToMesh.current.remove(oldTextMesh[end_index]);

          index_edit = end_index;
          setIndex(end_index);
          setIsStartPoint(false);
          setEdit(true);
          props.editableRuler(true);
          return;
        }
      }
    } 
    
  },[oldEndCircle, oldStartCircle, edit, ]);



  

  let lastBBox: Vector3;
  let lastConvex: Vector3;

  let lastIntersectionOnEditMove;

  function closeEditTouchForcefully(intersect) {
    console.log("Closing forcefully")
    //e.preventDefault();
    //let intersect = raycaster.intersectObjects([props.refToMesh.current, props.refToConvex.current], false);
    if (edit) {
      console.log("Closing edit..");
      //setEdit(false);
      if (intersect.length == 2) {
        let index_bbox = searchObjectByName(intersect, "bbox");
        let index_convex = searchObjectByName(intersect, "convex");
        startFace = oldFace[index_to_edit];
        console.log("Got intersection: ", intersect);

        let endFace = ''
        if (intersect[0].faceIndex !== undefined) {
          endFace = faces[intersect[index_bbox].faceIndex];
        }
        if (endFace !== startFace) {
          //console.log("Start Face: ", startFace, " end face: ", endFace)
          return
        }

        let line = oldLine[index_to_edit];
        startFace = oldFace[index_to_edit];
        const positions = (
          line.geometry.attributes.position
        ).array
        let end = props.refToMesh.current.worldToLocal(intersect[index_bbox].point);
        positions[3] = end.x
        positions[4] = end.y
        positions[5] = end.z
        line.geometry.attributes.position.needsUpdate = true;

        endCircle = makeCircle(radious);
        endCircle.position.copy(end.clone());

        // in teoria non dovrebbe servire
        // devo ruotare anche la label
        switch (startFace) {
          case 'A': endCircle.rotation.y = Math.PI * 0.5; break;
          case 'B': endCircle.rotation.y = -Math.PI * 0.5; break;
          case 'C': endCircle.rotation.x = -Math.PI * 0.5; break;
          case 'D': endCircle.rotation.x = Math.PI * 0.5; break;
          case 'E': break;
          case 'F': endCircle.rotation.y = Math.PI; break;
        }

        //let proj = computeProjection(intersect[0].point, intersect[1].point,
        //    intersect[0].normal);
        let newEnd = props.refToConvex.current.worldToLocal(intersect[index_convex].point);
        //let newEnd = intersect[1].point;
        //let start = props.refToMesh.current.worldToLocal(intersect[0].point);

        //endProjection = makeProjectionSegment(end, newEnd, intersect[0].normal);
        let endProjection2 = makeProjectionSegment(end, newEnd, intersect[index_convex].normal, props.sizeLine, gl.getSize(new Vector2()));

        props.refToMesh.current.add(endProjection2);
        props.refToMesh.current.add(endCircle);
        //setEdit(false);

        if (touchMoved) {
          console.log("Touch is moved")
        } else {
          console.log("Touch is not moved");
          // qua dentro devo aggiungere la linea e la misura
          let startPoint = new Vector3(positions[0],positions[1],positions[2]);
          line2 = makeLineSegments2(startPoint, endCircle.position, props.sizeLine, gl.getSize(new Vector2()));
          props.refToMesh.current.add(line2);
          pivot = oldPivot[index_to_edit];
          textMesh = oldTextMesh[index_to_edit];
          let plane_text = makePlane(0.5, 0.2 / 2);
          pivot.add(textMesh);
          pivot.add(plane_text);
          props.refToMesh.current.add(pivot);
          touchMoved = false;
        }

        const newLine = oldLine.map((element, index) => {
          if (index === index_to_edit) {
            return line;
          } else {
            return element
          }
        })
        setOldLine(newLine);

        const newLine2 = oldLine2.map((element, index) => {
          if (index === index_to_edit) {
            return line2;
          } else {
            return element;
          }
        })
        setOldLine2(newLine2);
        
        // qua devo fare il replace degli oggetti 
        if (isStartPoint) {
          console.log("Save new start point");
          // il vecchio punto iniziale diventa un nuovo punto finale
          // il vecchio punto finale diventa un nuovo punto iniziale
          
          const newStart = oldStartCircle.map((element,index)=> {
            if (index === index_to_edit) {
              return oldEndCircle[index_to_edit];
            } else {
              return element;
            }
          });
          setOldStartCircle(newStart);

          const newEndCircle = oldEndCircle.map((element,index)=> {
            if (index === index_to_edit) {
              return endCircle;
            } else {
              return element;
            }
          });
          setOldEndCircle(newEndCircle);

          const newStartProjection = oldStartProjection.map((element, index)=> {
            if (index === index_to_edit) {
              return oldEndProjection[index_to_edit];
            } else {
              return element;
            }

          });
          setOldStartProjection(newStartProjection);

          const newEndProjection = oldEndProjection.map((element, index)=> {
            if (index === index_to_edit) {
              return endProjection2;
            } else {
              return element;
            }
          });
          setOldEndProjection(newEndProjection);
          props.editableRuler(false);
          setEdit(false);
        } else {
          
          console.log("Save new end point");
          console.log("Saving after end ");
          //setEdit(false);
          // in questo caso salvo normalmente senza invertire i componenti
          // iniziali con quelli finali
          const newProjection = oldEndProjection.map((element, index) => {
            if (index === index_to_edit) {
              console.log("Replacing element at index: ", index)
              return endProjection2
            } else {
              return element;
            }
          });
          setOldEndProjection(newProjection);

          setOldEndCircle(oldEndCircle.map((element,index)=> {
            if (index === index_to_edit) {
              return endCircle;
            } else {
              return element;
            }
          }));
          props.editableRuler(false);
          setEdit(false);
        }
      }
    }
  }

  // in questa funzione aggiorno la misura
  const onTouchEditMove = useCallback((e: TouchEvent) => {
    e.preventDefault();
    console.log("Touch move...");
    if (edit) {
      console.log("Moving mouse in editing mode");
      //console.log("Index to edit: ", index_to_edit);
      //console.log("Is starting point? ", isStartPoint);
      let intersect = raycaster.intersectObjects([props.refToMesh.current,
        props.refToConvex.current], false)

        if (intersect.length != 2) {
          // significa che devo chiudere la misura perche'
          // ho sempre il d
          //printingLastIndex(lastBBox,lastConvex);
          //printLastIntersection(intersect);
          
          //printLastIntersection(lastIntersectionOnEditMove);
          closeEditTouchForcefully(lastIntersectionOnEditMove);
          return;

        }
        //console.log("Lenght of intersection: ",intersect.length);
        if (findMesh(props.meshName, intersect))
          //console.log("BBox found moving");
          if (findMesh(props.convexName, intersect))

            //lastBBox = intersect[0].point.clone();
            //lastConvex = intersect[1].point.clone();

            //lastIntersectionOnEditMove = intersect.;
            //console.log("Convex found moving");
            //console.log("Meshes found while moving...");
            if (intersect.length == 2) {
              lastIntersectionOnEditMove = Object.assign([], intersect);
              line = oldLine[index_to_edit];
              pivot = oldPivot[index_to_edit];
              textMesh = oldTextMesh[index_to_edit];
              startFace = oldFace[index_to_edit];
              plane = oldPlane[index_to_edit];
              props.refToMesh.current.add(pivot)
              const positions = (line.geometry.attributes.position).array
              const v0 = new Vector3(positions[0], positions[1], positions[2]);
              const v1 = new Vector3(
                intersect[0].point.x,
                intersect[0].point.y,
                intersect[0].point.z
              )

              touchMoved = true;
  
              let vl = props.refToMesh.current.worldToLocal(v1);
              //let vl = v1.clone();
              if (startFace == 'A' || startFace == 'B')
                vl.x = plane.x
              if (startFace == 'C' || startFace == 'D')
                vl.y = plane.y
              if (startFace == 'E' || startFace == 'F')
                vl.z = plane.z
  
              positions[3] = vl.x;
              positions[4] = vl.y;
              positions[5] = vl.z;
  
              props.refToMesh.current.remove(line2);
              line2 = updateLineSegments2(line2, v0, vl, props.sizeLine, gl.getSize(new Vector2()));
              setLine(line2);
              //console.log("Line2: ",line2);
              props.refToMesh.current.add(line2);
  
              line.geometry.attributes.position.needsUpdate = true;
              const distance: number = v0.distanceTo(vl) * 100;
              pivot.position.lerpVectors(v0, vl, 0.5);
  
              let inch = distance / 2.54;
              // conta solo il parametro height
              textMesh.geometry = new TextGeometry(distance.toFixed(0).toString() + "cm" + "/" + inch.toFixed(2).toString() + "in", {
                font: fontLoaded,
                size: 0.05,
                height: 0,
                bevelSize: 0.0005,
                bevelThickness: 0.0005,
              });
              textMesh.geometry.center();
              //setTouchMoved(true);
            }
    }
  },[edit, index_to_edit, isStartPoint]);

  // in questa funzione concludo la misura
  // e abilito nuovamente la misura
  const onTouchEditEnd = useCallback((e: TouchEvent) => {
    e.preventDefault();
    let intersect = raycaster.intersectObjects([props.refToMesh.current, props.refToConvex.current], false);
    if (edit) {
      console.log("Closing edit..");
      //setEdit(false);
      if (intersect.length == 2) {
        let index_bbox = searchObjectByName(intersect, "bbox");
        let index_convex = searchObjectByName(intersect, "convex");
        startFace = oldFace[index_to_edit];
        console.log("Got intersection: ", intersect);

        let endFace = ''
        if (intersect[0].faceIndex !== undefined) {
          endFace = faces[intersect[index_bbox].faceIndex];
        }
        if (endFace !== startFace) {
          //console.log("Start Face: ", startFace, " end face: ", endFace)
          return
        }

        let line = oldLine[index_to_edit];
        startFace = oldFace[index_to_edit];
        const positions = (
          line.geometry.attributes.position
        ).array
        let end = props.refToMesh.current.worldToLocal(intersect[index_bbox].point);
        positions[3] = end.x
        positions[4] = end.y
        positions[5] = end.z
        line.geometry.attributes.position.needsUpdate = true;

        endCircle = makeCircle(radious);
        endCircle.position.copy(end.clone());

        // in teoria non dovrebbe servire
        // devo ruotare anche la label
        switch (startFace) {
          case 'A': endCircle.rotation.y = Math.PI * 0.5; break;
          case 'B': endCircle.rotation.y = -Math.PI * 0.5; break;
          case 'C': endCircle.rotation.x = -Math.PI * 0.5; break;
          case 'D': endCircle.rotation.x = Math.PI * 0.5; break;
          case 'E': break;
          case 'F': endCircle.rotation.y = Math.PI; break;
        }

        //let proj = computeProjection(intersect[0].point, intersect[1].point,
        //    intersect[0].normal);
        let newEnd = props.refToConvex.current.worldToLocal(intersect[index_convex].point);
        //let newEnd = intersect[1].point;
        //let start = props.refToMesh.current.worldToLocal(intersect[0].point);

        //endProjection = makeProjectionSegment(end, newEnd, intersect[0].normal);
        let endProjection2 = makeProjectionSegment(end, newEnd, intersect[index_convex].normal, props.sizeLine, gl.getSize(new Vector2()));

        props.refToMesh.current.add(endProjection2);
        props.refToMesh.current.add(endCircle);
        setEdit(false);

        if (touchMoved) {
          console.log("Touch is moved")
        } else {
          console.log("Touch is not moved");
          // qua dentro devo aggiungere la linea e la misura
          let startPoint = new Vector3(positions[0],positions[1],positions[2]);
          line2 = makeLineSegments2(startPoint, endCircle.position, props.sizeLine, gl.getSize(new Vector2()));
          props.refToMesh.current.add(line2);
          pivot = oldPivot[index_to_edit];
          textMesh = oldTextMesh[index_to_edit];
          let plane_text = makePlane(0.5, 0.2 / 2);
          pivot.add(textMesh);
          pivot.add(plane_text);
          props.refToMesh.current.add(pivot);
          touchMoved = false;
        }

        const newLine = oldLine.map((element, index) => {
          if (index === index_to_edit) {
            return line;
          } else {
            return element
          }
        })
        setOldLine(newLine);

        const newLine2 = oldLine2.map((element, index) => {
          if (index === index_to_edit) {
            return line2;
          } else {
            return element;
          }
        })
        setOldLine2(newLine2);
        
        // qua devo fare il replace degli oggetti 
        if (isStartPoint) {
          console.log("Save new start point");
          // il vecchio punto iniziale diventa un nuovo punto finale
          // il vecchio punto finale diventa un nuovo punto iniziale
          
          const newStart = oldStartCircle.map((element,index)=> {
            if (index === index_to_edit) {
              return oldEndCircle[index_to_edit];
            } else {
              return element;
            }
          });
          setOldStartCircle(newStart);

          const newEndCircle = oldEndCircle.map((element,index)=> {
            if (index === index_to_edit) {
              return endCircle;
            } else {
              return element;
            }
          });
          setOldEndCircle(newEndCircle);

          const newStartProjection = oldStartProjection.map((element, index)=> {
            if (index === index_to_edit) {
              return oldEndProjection[index_to_edit];
            } else {
              return element;
            }

          });
          setOldStartProjection(newStartProjection);

          const newEndProjection = oldEndProjection.map((element, index)=> {
            if (index === index_to_edit) {
              return endProjection2;
            } else {
              return element;
            }
          });
          setOldEndProjection(newEndProjection);
          props.editableRuler(false);

        } else {
          
          console.log("Save new end point");
          console.log("Saving after end ");
          setEdit(false);
          // in questo caso salvo normalmente senza invertire i componenti
          // iniziali con quelli finali
          const newProjection = oldEndProjection.map((element, index) => {
            if (index === index_to_edit) {
              console.log("Replacing element at index: ", index)
              return endProjection2
            } else {
              return element;
            }
          });
          setOldEndProjection(newProjection);

          setOldEndCircle(oldEndCircle.map((element,index)=> {
            if (index === index_to_edit) {
              return endCircle;
            } else {
              return element;
            }
          }));
          props.editableRuler(false);
        }
      }
    }
  },[oldEndCircle, oldStartCircle, edit]);


  /*useEffect(() => {

    if (props.enabled) {
      gl.domElement.removeEventListener("touchstart", onTouchEditStart, false);
      gl.domElement.removeEventListener("touchmove",onTouchEditMove,false);
      gl.domElement.removeEventListener("touchend",onTouchEditEnd,false);
    } else {
      gl.domElement.addEventListener("touchstart", onTouchEditStart, false);
      gl.domElement.addEventListener("touchmove",onTouchEditMove,false);
      gl.domElement.addEventListener("touchend",onTouchEditEnd,false);
    }

  },[props.enabled]);*/

  useEffect(() => {
    console.log("Called")
    if (props.enabled === true) {
      gl.domElement.removeEventListener("touchstart", onTouchEditStart, false);
      gl.domElement.removeEventListener("touchmove",onTouchEditMove,false);
      gl.domElement.removeEventListener("touchend",onTouchEditEnd,false);
    } else {
      //gl.domElement.removeEventListener("click", onClickEdit, false);
      //gl.domElement.removeEventListener("mousemove", onMoveEdit, false);
      //gl.domElement.addEventListener("click", onClickEdit, false);
      //gl.domElement.addEventListener("mousemove", onMoveEdit, false);
      gl.domElement.removeEventListener("touchstart", onTouchEditStart, false);
      gl.domElement.removeEventListener("touchmove",onTouchEditMove,false);
      gl.domElement.removeEventListener("touchend",onTouchEditEnd,false);
      gl.domElement.addEventListener("touchstart", onTouchEditStart, false);
      gl.domElement.addEventListener("touchmove",onTouchEditMove,false);
      gl.domElement.addEventListener("touchend",onTouchEditEnd,false);
    }

    return () => {
      gl.domElement.removeEventListener("touchstart", onTouchEditStart, false);
      gl.domElement.removeEventListener("touchmove",onTouchEditMove,false);
      gl.domElement.removeEventListener("touchend",onTouchEditEnd,false);
    }

  }, [oldStartCircle, oldEndCircle, props.enabled, edit, index_to_edit, isStartPoint]);




  // gli eventi dovrebbero essere agganciati 
  // al dom del render}
  useEffect(() => {
    //gl.domElement.addEventListener("mousemove", onMoveEdit, false);
    if (props.enabled === true) {
      gl.domElement.addEventListener("click", onClickMeasure, false);
      gl.domElement.addEventListener("mousemove", onMouseMoveMeasure, false);
      document.addEventListener("keydown", interruptMeasure, false);

    } else if (props.enabled === false) {
      setMeasurePending(false);
      //gl.domElement.addEventListener("click", onClickEdit, false);
      gl.domElement.addEventListener("mousemove", onMouseMoveMeasure, false);


      //gl.domElement.addEventListener("click", onClickEndEdit, false);
    }
    if (props.enabled == false && measurePending == true) {
      //console.log("Trying to remove objs")
      removeObjects();
      //props.onEndMeasure(false);
    }

    return () => {
      console.log("Removing all the event listener")
      gl.domElement.removeEventListener("click", onClickMeasure, false);
      gl.domElement.removeEventListener("mousemove", onMouseMoveMeasure, false);
      document.removeEventListener("keydown", interruptMeasure, false);
      //removeObjects()
    }
  }, [props.enabled]);


  return (
    <></>
  )
}