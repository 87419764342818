import { Grid, Stack } from "@mui/material";
import { share, object3d3 } from "assets/icons";
import { MRCard } from "components/material";
import { MRButtonPrimary, MRButtonSecondary } from "components/material/Button";
import ShareModal from "components/Share/ShareModal";
import useCanForwardBlock from "hooks/services/shares/useCanForwardBlock";
import useToasts from "hooks/useToasts";
import { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { colors } from "styles/theme";
import { Block } from "types/block";
import { SingleBlockWrapper } from "./styles";
import defaultThumbnail from "assets/images/default-thumb.png";
import PageHeader from "components/Layout/PageHeader";
import useAuth from "hooks/useAuth";
import ThumbDisplayer from "components/ThumbDisplayer";

const SingleBlock: FC<{ block: Block; fromLink?: string }> = ({
  block,
  fromLink,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [openShareModal, setOpenShareModal] = useState<boolean>(false);

  const {
    data: canForward,
    refetch: getCanForward,
    isLoading: canForwardLoading,
    error: canForwardError,
  } = useCanForwardBlock(block._id, false);

  useEffect(() => {
    if (user) getCanForward();
  }, []);

  useToasts([
    {
      severity: "error",
      message: t("Something went wrong. Share is temporarily disabled."),
      condition: !!canForwardError,
    },
  ]);

  return (
    <SingleBlockWrapper>
      {!fromLink && (
        <PageHeader>
          <h1>{block.title}</h1>
        </PageHeader>
      )}

      <Stack maxWidth={"500px"} margin={"50px auto"}>
        <ThumbDisplayer
          imgProps={{
            src: block.model?.thumbnail || defaultThumbnail,
            style: { objectFit: "contain", width: "512px", height: "512px" },
            alt: "Block Thumbnail",
          }}
        />
      </Stack>

      {block.ownerProfile?.companyLogoUrl && (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          marginBottom={"30px"}
        >
          <img
            src={block.ownerProfile?.companyLogoUrl}
            style={{
              height: "128px",
              width: "128px",
              objectFit: "contain",
            }}
            alt={t("Company logo")}
          />
        </Stack>
      )}

      <Stack direction="row" justifyContent={"center"} gap={5}>
        <Link
          to={
            fromLink
              ? `/viewer/block/${block._id}?linkCode=${fromLink}`
              : `/viewer/block/${block._id}`
          }
        >
          <MRButtonPrimary
            sx={{
              bgcolor: `${colors.green} !important`,
              color: `${colors.black} !important`,
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={1}
            >
              <img src={object3d3} color="green" />
              <span>{t("View in 3D")}</span>
            </Stack>
          </MRButtonPrimary>
        </Link>
        {((!canForwardLoading && canForward && canForward.data && !fromLink) ||
          (user && block.owner === user?._id)) && (
          <>
            <MRButtonSecondary
              sx={{ border: `solid 0.5px ${colors.green}!important` }}
              onClick={() => setOpenShareModal(true)}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                gap={1}
              >
                <img src={share} alt="Share" />
                <span>{t("Share")}</span>
              </Stack>
            </MRButtonSecondary>
            <ShareModal
              open={openShareModal}
              setOpen={setOpenShareModal}
              blockId={block._id}
              forward={
                canForward && canForward.data && block.owner !== user?._id
              }
            />
          </>
        )}
      </Stack>

      <MRCard sx={{ marginTop: "50px" }}>
        <Stack alignItems={"center"}>
          <h2 style={{ marginBottom: "0" }}>{t("Model Info")}</h2>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={3}
            padding={5}
            paddingTop={3}
          >
            {block.ownerProfile && (
              <Grid item xs={6} sm={6} md={4} lg={4}>
                <p>{t("Company")}</p>
                <span>{block.ownerProfile?.companyName}</span>
              </Grid>
            )}

            <Grid item xs={6} sm={6} md={4} lg={4}>
              <p>{t("Location")}</p>
              <span>{block.placeOfAcquisition || "--"}</span>
            </Grid>

            <Grid item xs={6} sm={6} md={4} lg={4}>
              <p>{t("Material")}</p>
              <span>{block.material}</span>
            </Grid>

            <Grid item xs={6} sm={6} md={4} lg={4}>
              <p>{t("Title")}</p>
              <span>{block.title}</span>
            </Grid>

            <Grid item xs={6} sm={6} md={4} lg={4}>
              <p>{t("Sizes")}</p>
              <Stack direction={"column"} justifyContent={"center"}>
                <Stack justifyContent={"center"}>
                  <span>{`${block.length}cm - ${block.depth}cm - ${block.height}cm`}</span>
                </Stack>

                <Stack
                  justifyContent={"center"}
                  style={{ color: colors.green }}
                >
                  <span>{`(${
                    Math.round((block.length / 2.54) * 100) / 100
                  }in - ${
                    Math.round((block.depth / 2.54) * 100) / 100
                  }in - ${
                    Math.round((block.height / 2.54) * 100) / 100
                  }in)`}</span>
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={6} sm={6} md={4} lg={4}>
              <p>{t("Geological family")}</p>
              <span>{`${block.geologicalFamily || "--"}`}</span>
            </Grid>

            <Grid item xs={6} sm={6} md={4} lg={4}>
              <p>{t("Weight")}</p>
              <span>{`${block.weight} Kg`}</span>
            </Grid>

            {block.ownerProfile && (
              <Grid item xs={6} sm={6} md={4} lg={4}>
                <p>{t("Owner")}</p>
                <span>
                  {block.ownerProfile?.firstname +
                    " " +
                    block.ownerProfile?.lastname}
                </span>
              </Grid>
            )}
          </Grid>
        </Stack>
      </MRCard>
    </SingleBlockWrapper>
  );
};

export default SingleBlock;
