import { Canvas, RootState } from "@react-three/fiber"
import { OrbitControls } from "@react-three/drei";
import { Stack, Box, SvgIcon, IconButton } from "@mui/material";
import Loader from "components/Loader"
import { useState, useEffect, useRef } from "react";
import { Group, Mesh, Box3, Vector3, BoxGeometry, Euler, Color, SRGBColorSpace } from "three";
import { Line2 } from "three/examples/jsm/lines/Line2"
import { ConvexGeometry } from 'three/examples/jsm/geometries/ConvexGeometry.js';
import FreeDrawTool from "components/Viewer3D/components/FreeDrawTool";
import MeasureTool from "components/Viewer3D/components/MeasureTool";
import ZoomController from "components/Viewer3D/components/ZoomController";
import { useNavigate, useSearchParams } from "react-router-dom";
import html2canvas from "html2canvas";

import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor';

import ModeIcon from '@mui/icons-material/Mode';


import moment from "moment";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

//import ToggleVisibilityButton from "./buttons/ToggleVisibilityButton";

//import ToolsButton from "./newui/ToolsButtons";
import ResetButton from "./newui/ResetButton";
import ScreenshotButton from "./newui/ScreenshotButton";
//import HelpButton from "./newui/HelpButton";
//import InfoBlockButton from "./newui/InfoBlockButton";
import LightsButton from "./newui/LightsButton";
//import GoBackButton from "./newui/GoBackButton";
import Logo from "./newui/Logo";


import NavBar from "./ui-desk/NavBar"
import BottomBar from "./ui-desk/BottomBar"
//import GoBackButton from "./GoBackButton"



import MarmoReal from './ui-logo/MarmoReal'
import MarmoRealLogo from './ui-logo/MarmoRealLogo'


import InfoBlockButton from "./ui-desk/InfoBlockButton"
import ToolsButton from "./ui-desk/ToolsButton"
import HelpButton from "./ui-desk/HelpButton"
import GoBackButton from "./ui-components/GoBackButton";
import ToolsButtonMobile from './ui-mobile/ToolsButtonMobile'
import InfoBlockButtonMobile from "./ui-mobile/InfoBlockButtonMobile"
import HelpButtonMobile from "./ui-mobile/HelpButtonMobile"

import MarmoRealLogoTondo from './ui-logo/MarmoRealLogoTondo'

import MarmoRealLogoCompleto from './ui-logo/MarmoRealLogoCompleto'

import { UAParser } from 'ua-parser-js';

import ActionButtonSfondo from './ui-mobile/ActionButtonSfondo'

import StraightenIcon from '@mui/icons-material/Straighten';


import { Environment } from '@react-three/drei'
import EnvManager from "./components/EnvManager";
import zIndex from "@mui/material/styles/zIndex";
import { position } from "html2canvas/dist/types/css/property-descriptors/position";
import { color } from "html2canvas/dist/types/css/types/color";

import Button from '@mui/material/Button';


interface Viewer3DProps {
  height: number;
  width: number;
  modelSrc: string /**the url to the .obj resource */;
  companyLogo?: string;
  modelName: string,
  material: string,
  dimension: string,
  dimInch?: string
}

export default function Viewer3D(props: Viewer3DProps) {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isMobile, setMobile] = useState<boolean>(false);
  const heightDesktop = "60px";
  const heightMobile = "40px";

  const refAnchor = useRef<HTMLElement>(null);
  const refAnchorMobile = useRef<HTMLElement>(null);


  const goBack = () => {
    const returnUrl = searchParams.get("returnUrl");
    if (returnUrl) {
      navigate(returnUrl);
      return;
    }
    navigate(-1);
  };

  const [myState, setState] = useState<boolean>(false);
  //const [loaded, setLoaded] = useState<boolean>(false);
  const [measure, enableMeasure] = useState<boolean>(false);
  const [measurePending, setPending] = useState<boolean>(false);
  const [freeDraw, enableFreeDraw] = useState<boolean>(false);
  const [stateCanvas, setStateCanvas] = useState<RootState>(null!);
  const [loading, setLoading] = useState<number | boolean>(false);
  const [gltfModel, setGltf] = useState<Mesh>(null!);

  // ad ora queste non sono utilizzate
  const [freeLine, setFreeLine] = useState<Line2[]>([]);

  const [lineVisible, setLineVisible] = useState<boolean>(true);

  const [initDistance, setInitDistance] = useState<number>(0);


  const refActiveButton = useRef<HTMLElement>(null!)

  /*const updateMeasure = (measure: MeasureType): void => {
    setMeasure(current => [...current, measure]);
  }*/

  /*const modelLoaded = useCallback(() => {
    setState(true);
  }, []) */


  const refGroup = useRef<Group>(null!);
  const refModel = useRef<Mesh>(null!);
  const refBBox = useRef<Mesh>(null!);
  const refConvex = useRef<Mesh>(null!);
  const refControls = useRef<any>(null!);
  const refBox3 = useRef<Box3>(null!);

  const [editRuler, setEditRuler] = useState<boolean>(false);



  useEffect(() => {
    window.createImageBitmap = undefined;
    //console.log("Setting to undefined...")
    const loader = new GLTFLoader();
    // props.modelSrc
    //const modelName = 'components/Viewer3D/components/1 - Patagonya.glb'
    loader.load(props.modelSrc,
      function (gltf) {
        let model = gltf.scene.children[0] as Mesh
        setGltf(model)
        setState(true);
        let parser = new UAParser();
        let dev = parser.getDevice()?.type;
        if (dev !== undefined && dev === 'mobile') {
          setMobile(true);
        }

      },
      function (xhr) {
        const completed = Math.floor((xhr.loaded / xhr.total) * 100);
        setLoading(completed == 100 ? false : completed);
      },
      function (error) {
        console.log("Error: ", error);
      }
    )

  }, [])

  function changeVisibilityChildren(mesh: Mesh, visibility: boolean): void {
    mesh.children.forEach((child) => {
      child.visible = visibility
    })
  }

  function setVisibility() {
    if (lineVisible == false) {
      changeVisibilityChildren(refConvex.current, true);
      changeVisibilityChildren(refBBox.current, true);
      setLineVisible(true);
    } else {
      changeVisibilityChildren(refConvex.current, false);
      changeVisibilityChildren(refBBox.current, false);
      setLineVisible(false);
    }
    if (freeDraw) {
      enableFreeDraw(false);
    }
    
  }

  const makeScreenShot = () => {
    // se disabilito preserveBuffer, devo eseguire
    // il render forzato prima di fare lo screen
    //stateCanvas.gl.render(stateCanvas.scene, stateCanvas.camera);
    let container = document.createElement("div");
    container.style.position = "relative";
    container.id = "to-clone";
    let sceneImg = document.createElement("img");
    sceneImg.src = stateCanvas.gl.domElement.toDataURL();
    container.appendChild(sceneImg);
    let measures = document.getElementsByClassName("measurementLabel")
    console.log("Measures: ", measures)
    document.body.appendChild(container);
    console.log("Container is: ", container)
    html2canvas(document.getElementById("to-clone") as HTMLElement, {
      width: stateCanvas.gl.domElement.width,
      height: stateCanvas.gl.domElement.height,
      logging: false
    }).then(
      (canvas) => {
        let a = document.createElement("a");
        a.download = `Marmoreal-screenshot-${moment()}.png`;
        a.href = canvas.toDataURL("image/png");
        a.click();
        document.body.removeChild(
          document.getElementById("to-clone") as HTMLElement
        );
      }
    );
  };

  const areOpsInProgress = (): boolean => {
    return measure || freeDraw;
  }

  const checkEnableMeasure = () => {
    // non ho operazioni attive
    /*if (!measure && !freeDraw) {
      console.log("Enabling measure");
      refControls.current.enabled = false;
      enableMeasure(true);
      // ho fatto delle misure, ora disabilito
    } else if (measure && !freeDraw) {
      refControls.current.enabled = true;
      refControls.current.update();
      enableMeasure(false);
    }*/
    if (!measure) {
      refControls.current.enabled = false;
      enableMeasure(true);
    } else {
      refControls.current.enabled = true;
      refControls.current.update();
      enableMeasure(false);
    }
    if (freeDraw) {
      enableFreeDraw(false)
    }
  }

  const checkEnableFreeDraw = () => {
    // non ho operazioni attive
    /*if (!measure && !freeDraw) {
      refControls.current.enabled = false;
      enableFreeDraw(true);
      // ho segnato il blocco, ora disabilito
    } else if (freeDraw && !measure) {
      refControls.current.enabled = true;
      refControls.current.update();
      enableFreeDraw(false);
    }*/
    if (!freeDraw) {
      refControls.current.enabled = false;
      enableFreeDraw(true)
    } else {
      refControls.current.enabled = true;
      refControls.current.update();
      enableFreeDraw(false);
    }
    if (measure) {
      enableMeasure(false);
    }
  }

  // queste potrebbero essere una sola
  // e far cambiare il parametro
  const zoomIn = () => {
    if (!areOpsInProgress()) {
      stateCanvas.camera.zoom += 0.2;
      stateCanvas.camera.updateProjectionMatrix();
      refControls.current.update();
      console.log("Camera zoom: ", stateCanvas.camera.zoom)
    }
  }

  const zoomOut = () => {
    if (!areOpsInProgress()) {
      stateCanvas.camera.zoom -= 0.2;
      stateCanvas.camera.updateProjectionMatrix();
      refControls.current.update();
      //console.log("Camera zoom: ", stateCanvas.camera.zoom)
    }
  }

  // rotate on X axis
  const rotateV = () => {
    if (!areOpsInProgress()) {
      refGroup.current.children.forEach((child) => {
        child.rotation.x += Math.PI / 2;
        child.updateMatrix();
      })
      refBBox.current.geometry.computeBoundingBox();
      refBox3.current = new Box3().setFromObject(refBBox.current);
    }
  }

  // rotate on Y axis
  const rotateH = () => {
    if (!areOpsInProgress()) {
      refGroup.current.children.forEach((child) => {
        child.rotation.y += Math.PI / 2;
        child.updateMatrix();
      })
      refBBox.current.geometry.computeBoundingBox();
      refBox3.current = new Box3().setFromObject(refBBox.current);
      stateCanvas.camera.updateProjectionMatrix();
    }
  }


  // translate on Y axis
  const translateV = (dir: number) => {
    const amount = (refBox3.current.max.y - refBox3.current.min.y) / 2;
    if (!areOpsInProgress()) {
      refGroup.current.children.forEach((child) => {
        child.translateY(dir * amount);
      })
      refBBox.current.geometry.computeBoundingBox();
      refBox3.current = new Box3().setFromObject(refBBox.current);
    }
  }

  // translate on X axis
  // riesco a resettare ma le misure sono sbagliate
  // quando calcolo le proiezioni. Provare a calcolare
  // le coordinate locali anche della seconda mesh
  const translateH = (dir: number) => {
    const amount = (refBox3.current.max.x - refBox3.current.min.x) / 2;
    console.log("Center before tranlsation : ", refBox3.current.getCenter(new Vector3()))
    if (!areOpsInProgress()) {
      refGroup.current.children.forEach((child) => {
        child.translateX(dir * amount)
      })
      refBBox.current.geometry.computeBoundingBox();
      refBox3.current = new Box3().setFromObject(refBBox.current);
    }
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    //console.log("DOcument style: ",document.body.style.overflow = 'hidden');
    return () => {
      document.body.style.overflow = 'auto';
    }
  }, [])

  const deleteChildren = (mesh: Mesh): void => {
    let children = mesh.children;
    while (children.length > 0) {
      mesh.remove(children[0]);
      children = mesh.children;
    }
  }

  const resetGroup = () => {
    refGroup.current.position.copy(new Vector3(0, 0, 0));
    refGroup.current.children.forEach((child) => {
      child.position.copy(new Vector3(0, 0, 0));
      child.rotation.copy(new Euler(0, 0, 0));
    })
    deleteChildren(refBBox.current);
    deleteChildren(refConvex.current);
    refBBox.current.geometry.computeBoundingBox();
    refBox3.current = new Box3().setFromObject(refBBox.current);
    enableFreeDraw(false);
    stateCanvas.camera.position.set(0, 0, 5);
    stateCanvas.camera.zoom = 1;
    stateCanvas.camera.updateProjectionMatrix();
    refControls.current.reset();
    refControls.current.update();
  }

  useEffect(() => {
    if (refBBox.current !== null) {
      let box3 = new Box3().setFromObject(gltfModel, true);
      box3.getCenter(gltfModel.position);
      let size = box3.getSize(new Vector3());
      gltfModel.geometry.center();
      gltfModel.updateMatrix();

      //console.log(size)
      const offset = 0.05;
      // creando la box con il flag precise=true,
      // in rari casi puo' accadere che la mesh 
      // del modello e la bbox siano a "pari"
      // vedi test con blocco 11 e 4
      refBBox.current.geometry = new BoxGeometry(size.x + offset, size.y + offset, size.z + offset);
      let points = [];
      let pointGeometry = gltfModel.geometry.attributes.position.array;
      for (let i = 0; i < pointGeometry.length; i += 3) {
        points.push(new Vector3(pointGeometry[i], pointGeometry[i + 1], pointGeometry[i + 2]))
      }
      let cg = new ConvexGeometry(points);
      refConvex.current.geometry = cg;
      refConvex.current.geometry.computeBoundingBox();
      refConvex.current.geometry.boundingBox.getCenter(gltfModel.position);
      refConvex.current.geometry.applyQuaternion(gltfModel.quaternion);
      refConvex.current.updateMatrix();
      refConvex.current.geometry.attributes.position.needsUpdate = true;
      refGroup.current.position.set(0, 0, 0);
      let newBox3: Box3 = new Box3().setFromObject(refBBox.current);
      refBox3.current = newBox3;
      let dist = newBox3.distanceToPoint(stateCanvas.camera.position);
      setInitDistance(dist);
      
    }
  }, [refBBox.current])

  const envs = ["/studio_small.hdr", "/carroponte.exr", "/studio2k.exr", "/Bianco_2k.exr"];
  const [currentEnvIndex, setCurrentEnvsIndex] = useState<number>(3);

  const setEnvStudio = () => {
    setCurrentEnvsIndex(2);
  }

  const setEnvOut = () => {
    setCurrentEnvsIndex(1);
  }

  const setEnvDiff = () => {
    setCurrentEnvsIndex(3);
  }

  return (

    gltfModel ? (
      <>
        <Canvas
          //dpr={props.width / props.height}
          onCreated={(state) => setStateCanvas(state)}
          gl={{ preserveDrawingBuffer: true, outputColorSpace: SRGBColorSpace }}
          camera={{ near: 0.01, position: [0, 0, 5] }}
          scene={{ background: new Color(0x808080) }}
        >

          <EnvManager files={envs} />
          <Environment files={envs[currentEnvIndex]} background={currentEnvIndex == 3 ? false : true} />

          <OrbitControls
            ref={refControls}
            zoomSpeed={0.5}
            minZoom={0.8}
            rotateSpeed={0.5}
            minDistance={1}
            maxDistance={8}
            enabled={!(measure || measurePending || freeDraw || editRuler)}
            panSpeed={0.5}

            makeDefault={true}
          //zoomToCursor={true}

          />
          <ZoomController
            refToControls={refControls}
            refToMesh={refBox3}
            initDistance={initDistance}
          />
          <FreeDrawTool
            color="red"
            size={2}
            enabled={freeDraw}
            meshName="prova"
            suffix="draw"
            refMesh={refConvex}
          /*updateLine={updateLine}*/
          />
          <MeasureTool
            color="red"
            enabled={measure}
            meshName="bbox"
            convexName="convex"
            suffixLabel="measure"
            refToMesh={refBBox}
            refToConvex={refConvex}
            refToBase={refModel}
            //isPending={setPending}
            sizeLine={2}
            onEndMeasure={enableMeasure}
            editableRuler={setEditRuler}
          />
          <group ref={refGroup}>

            <mesh ref={refModel} name="base">
              <primitive object={gltfModel} />
            </mesh>

            <mesh ref={refBBox} name="bbox">
              <meshBasicMaterial color={0xFF0000} wireframe={true} transparent={true} opacity={0} />
            </mesh>

            <mesh ref={refConvex} name="convex">
              <meshBasicMaterial color={0x0000FF} wireframe={true} transparent={true} opacity={0} />
            </mesh>

          </group>
        </Canvas>

        {!isMobile ? (
          <>
            <NavBar height={heightDesktop}>
              <Box component={"div"}
                display={"flex"}
                width={"100%"}
                sx={{
                  position: "relative",
                  zIndex: "1300",
                  height: "100%"
                }}>
                <GoBackButton onClick={() => goBack} />
              </Box>

              <Box component={"div"}
                display={"flex"}
                width={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                  zIndex: "1500",
                  height: "100%"
                }}>
                <SvgIcon viewBox={"0 0 215 60"} sx={{ width: 215, height: 60 }}>
                  <MarmoRealLogoCompleto />
                </SvgIcon>
              </Box>

              <Box component={"div"}
                ref={refAnchor}
                display={"flex"}
                width={"100%"}
                justifyContent={"right"}
                alignItems={"center"}
                sx={{
                  zIndex: "1400",
                  height: "100%"
                }}>
                <Stack direction={"row"}
                  gap={5}
                  marginRight={"2%"}
                  display={"flex"}
                  height={"100%"}
                >
                  <InfoBlockButton
                    modelName={props.modelName}
                    material={props.material}
                    dimension={props.dimension}
                    dimInch={props.dimInch}
                  />
                  <HelpButton />
                  <ToolsButton anchor={refAnchor}
                    onClickMeasure={checkEnableMeasure}
                    onClickFreeDraw={checkEnableFreeDraw}
                    onClickReset={resetGroup}
                    onClickToggleVisibility={setVisibility}
                    setEnvLight={setEnvOut}
                    setEnvStudioLight={setEnvStudio}
                    setEnvDiffLight={setEnvDiff}
                  />
                </Stack>
              </Box>
            </NavBar>

            {freeDraw ? <Box
              component={"div"}
              display={"flex"}
              position={"fixed"}
              bottom={heightDesktop}
              paddingBottom={"30px"}
              style={{width: "100%"}}
              justifyContent={"center"}
              zIndex={"1400"}
            >
                <Button disableRipple
                  title="Disable free draw" 
                  sx={{border: 1, borderRadius: "50%", padding: 3, color: "rgba(6,11,12,0.75)"}}
                  onClick={checkEnableFreeDraw}
                  style={{backgroundColor: "rgba(6,11,12,0.75)"}}
                >
                  <ModeIcon sx={{color: "#4BBECF"}}/>
                </Button>
              
            </Box> : <></>}

            <BottomBar height={heightDesktop}>
              <Box component={"div"}
                display={"flex"}
                width={"100%"}
                justifyContent={"right"}
                alignItems={"center"}
                sx={{
                  zIndex: "1400",
                  height: "100%"
                }}
                paddingRight={1}
              >

                <IconButton disableRipple title="Screenshot" onClick={makeScreenShot}>
                  <ScreenshotMonitorIcon width={32} height={29} sx={{ color: "#4BBECF" }} />
                </IconButton>
              </Box>

              
            </BottomBar>
          </>
        ) : (
          <>
            <NavBar height={heightMobile}>
              <Box component={"div"}
                marginLeft={"2%"}
              >
                <InfoBlockButtonMobile
                  modelName={props.modelName}
                  material={props.material}
                  dimension={props.dimension}
                  dimInch={props.dimInch}
                />
              </Box>
              <Box component={"div"}
                display={"flex"}
                width={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
                paddingTop={"40px"}
                sx={{
                  zIndex: "1500",
                  height: "100%"
                }}>
                
                
              </Box>

              <Box component={"div"}
                marginRight={"2%"} >
                <HelpButtonMobile />
              </Box>
            </NavBar>

            {freeDraw ? <Box
              component={"div"}
              display={"flex"}
              position={"fixed"}
              top={heightMobile}
              paddingBottom={"15px"}
              style={{width: "100%"}}
              justifyContent={"center"}
              zIndex={"1400"}
              marginTop={1}
            >
                <Button disableRipple
                  title="Disable free draw" 
                  sx={{border: 1, borderRadius: "50%", padding: 2, color: "rgba(6,11,12,0.75)"}}
                  onClick={checkEnableFreeDraw}
                  style={{backgroundColor: "rgba(6,11,12,0.75)"}}
                >
                  <ModeIcon sx={{color: "#4BBECF"}}/>
                </Button>
              
            </Box> : <></>}


            <BottomBar height={heightMobile}>
              <Box component={"div"}
                display={"flex"}
                width={"100%"}
                sx={{
                  position: "relative",
                  zIndex: "1300",
                  height: "100%"
                }}>
                <GoBackButton onClick={() => goBack}/>
              </Box>

              <Box component={"div"}
                display={"flex"}
                width={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
                paddingBottom={"40px"}
                sx={{
                  zIndex: "1500",
                  height: "100%"
                }}>

                  
                
                {true ? (<SvgIcon viewBox={"0 0 101 92"} sx={{ width: 101, height: 92 }} fontSize={"small"}>
                  <MarmoRealLogoTondo />
                </SvgIcon>): (<>
                <Box
                  component={"div"}
                  //paddingBottom={"40px"}
                >

                
                    <IconButton  sx={{backgroundColor: "#00a4b7", width: "80px", height: "80px"}}>
                      <StraightenIcon fontSize="large" />
                    </IconButton>
               

                </Box>
                </>) }
              </Box>

              <Box
                component={"div"}
                ref={refAnchorMobile}
                display={"flex"}
                width={"100%"}
                justifyContent={"right"}
                alignItems={"center"}
                sx={{
                  zIndex: "1400",
                  height: "100%"
                }}>
                <Stack direction={"row"}
                  gap={3}
                  marginRight={"2%"}
                  display={"flex"}
                  height={"100%"}
                >

                  <IconButton disableRipple onClick={makeScreenShot}>
                    <ScreenshotMonitorIcon sx={{ color: "#4BBECF" }} />
                  </IconButton>
                  <ToolsButtonMobile
                    activeButton={refActiveButton}
                    anchor={refAnchorMobile}
                    onClickMeasure={checkEnableMeasure}
                    onClickFreeDraw={checkEnableFreeDraw}
                    onClickReset={resetGroup}
                    onClickToggleVisibility={setVisibility}
                    setEnvLight={setEnvOut}
                    setEnvStudioLight={setEnvStudio}
                    setEnvDiffLight={setEnvDiff}
                  />
                </Stack>
              </Box>
            </BottomBar>

          </>
        )
        }
      </>
    ) : (
      <>
        <Stack
          sx={{
            position: "fixed",
            top: "50%",
            zIndex: "1202",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
          gap={2}
        >
          <Loader /> <span>Loading.. {loading !== true && loading !== false && `${loading}%`}</span>
        </Stack>
      </>
    )

  )
}
